import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  Img,
  Link,
  Input,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import _ from 'lodash'
import Seo from '../components/seo'

import logo from '../images/logo.png'

const BACKEND_URL = 'https://us-central1-upstager-dev.cloudfunctions.net/p/sendBookDemoNotification'

const DemoSchedule = () => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState('')

  const { isOpen, onOpen } = useDisclosure()

  const onSubmit = async () => {
    setLoading(true)
    await fetch(BACKEND_URL, {
      method: 'POST',
      body: JSON.stringify(
        _.omitBy(
          {
            name,
            email
          },
          _.isEmpty
        )
      )
    })
    onOpen()
    setLoading(false)
  }

  return (
    <>
      <Seo title="Upstager / Demo schedule" />
      <Flex bg="white" w="full" h="100vh" justify="center" align="center" direction="column" px="1.125rem">
        <Link as={GatsbyLink} to="/" width="13.5rem" height="5.5rem" mb="1.5rem">
          <Img objectFit="contain" w="full" h="full" src={logo} />
        </Link>
        <Heading fontSize="2.5rem" mb="1.5rem" textAlign="center">
          See Upstager in action
        </Heading>
        <Text fontSize="0.875rem" mb="1.5rem" textAlign="center">
          See how you can stage your virtual tours and impress real estate buyers with Upstager.
        </Text>
        <Flex direction="column" w="25rem" gap="1rem">
          <Input
            placeholder="Full name*"
            px="1.05rem"
            fontSize="0.875rem"
            letterSpacing="-0.025rem"
            height="3rem"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Input
            placeholder="Email*"
            px="1.05rem"
            fontSize="0.875rem"
            letterSpacing="-0.025rem"
            height="3rem"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            variant="solid"
            height="3rem"
            fontSize="1.125rem"
            colorScheme="green"
            onClick={onSubmit}
            isDisabled={_.isEmpty(email) || _.isEmpty(name)}
            isLoading={loading}
          >
            Show me how
          </Button>
        </Flex>
      </Flex>
      <Modal size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody h="full" w="full">
            <Box>
              <VStack align="center" h="full" w="full" mt="30vh" spacing="4">
                <Heading>Thank you!</Heading>
                <Text>We’ll reach out with the next steps very soon.</Text>
              </VStack>
              <VStack align="center" h="full" w="full" mt="12vh">
                <Button as={Link} href="https://upstager.co/" w="8rem" colorScheme="green">
                  Okay
                </Button>
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DemoSchedule
